import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';
import {
    IDialogMessages, IChatMessages, IPagination,
    ISelectedMessages, ITextMessage,
    IEditMessage, IGeoMessage, IPhotoMessage,
    IVoiceMessage, IDeletedMessage, ISentMessage
} from './types';

export const chatAPI = createApi({
    reducerPath: 'chat',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (build) => ({
        getDialogs: build.query<IDialogMessages, IPagination>({
            query: (payload) => ({
                url: `${Entity.chat}/?limit=${payload.limit}&offset=${payload.offset}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        getMessage: build.query<IChatMessages, number>({
            query: (id) => ({
                url: `${Entity.message}/${id}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        getMessages: build.query<IChatMessages, IPagination>({
            query: (payload) => ({
                url: `${Entity.chat}/${payload.id}?limit=${payload.limit}&offset=${payload.offset}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        postTextMessage: build.mutation<ISentMessage, ITextMessage>({
            query: (payload) => ({
                url: `${Entity.chat}/${payload.id}`,
                method: 'POST',
                body: { message: payload.message, reply_to: payload.reply_to },
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        putTextMessage: build.mutation<ISentMessage, IEditMessage>({
            query: (payload) => ({
                url: `${Entity.message}/${payload.messageId}`,
                method: 'PUT',
                body: { reply_to: payload.reply_to, message: payload.message },
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        postPhotoMessage: build.mutation<ISentMessage, IPhotoMessage>({
            query: (payload) => ({
                url: `${Entity.chat}/${payload.get('id')}/photo`,
                method: 'POST',
                body: payload,
                headers: { ...getDefaultHeaders(true), ...getDynamicHeaders() },
            }),
        }),
        postVoiceMessage: build.mutation<ISentMessage, IVoiceMessage>({
            query: (payload) => ({
                url: `${Entity.chat}/${payload.get('id')}/voice`,
                method: 'POST',
                body: payload,
                headers: { ...getDefaultHeaders(true), ...getDynamicHeaders() },
            }),
        }),
        postGeoMessage: build.mutation<ISentMessage, IGeoMessage>({
            query: (payload) => ({
                url: `${Entity.chat}/${payload.id}/location`,
                method: 'POST',
                body: { reply_to: payload.reply_to, lat: payload.lat, lon: payload.lon },
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        deleteMessage: build.mutation<IDeletedMessage, ISelectedMessages>({
            query: (payload) => ({
                url: `${Entity.message}`,
                method: 'DELETE',
                body: payload,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
    }),
});